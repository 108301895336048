import { StatusBar } from 'react-native';

// @needsAudit
/**
 * Set the translucency of the status bar.
 * @param translucent Whether the app can draw under the status bar. When `true`, content will be
 * rendered under the status bar. This is always `true` on iOS and cannot be changed.
 * @platform android
 */
export default function setStatusBarTranslucent(translucent: boolean) {
  StatusBar.setTranslucent(translucent);
}
